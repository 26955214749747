// SocketEvents.ts
export enum SocketEvent {
  // Socket IO Events
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  CONNECT_ERROR = 'connect_error',
  OFFLINE = 'offline',

  // Custom Error Event
  ERROR = 'socket_error',

  MESSAGE = 'message',
  // Playlist Events
  SEARCH = 'search',
  ADD_TO_PLAYLIST = 'add_to_playlist',
  REMOVE_FROM_PLAYLIST = 'remove_from_playlist',
  PLAYLIST_UPDATE = 'playlist_update',
  UPDATE_PLAYER_SETTINGS = 'update_player_settings',
  PLAYLIST_PLAY = 'playlist_play',

  // Player Control Events
  PLAYER_PLAY = 'player_play',
  PLAYER_PAUSE = 'player_pause',
  PLAYER_NEXT = 'player_next',
  // ... add other events as needed
}

export enum SocketType {
  CLIENT = 'client',
  PLAYER = 'player',
}

export interface MessagePayload {
  message: string;
}

export interface SearchEventPayload {
  query: string;
  pageToken?: string;
}

export interface AddToPlaylistEventPayload {
  videoDetail: VideoDetails;
}

export interface RemoveFromPlaylistEventPayload {
  videoIndex: number;
}

export interface VideoDetails {
  id: string;
  title: string;
  channelName: string;
  duration: number; // seconds in number
  thumbnail: string;
  singer?: string;
}

export interface SearchEventResponse {
  items: VideoDetails[];
  nextPageToken?: string;
}

export interface PlayerMeta {
  isPlaying: boolean;
  next?: number; // If next item is queued, otherwise undefined.
  isAutoPlay: boolean;
  currentIndex: number;
  currentVideo?: VideoDetails;
  currentProgress: number;
}

export interface PlayerSettings {
  showIdleScreen?: boolean;
  musicPlayControlLocked?: boolean;
  playlistSearchLock?: boolean;
  playlistSelectLock?: boolean;
  playlistDeleteLock?: boolean;
  autoRemovePlayedTracks?: boolean;
  skipDeleteThreshold?: number;
  askWhoSing?: boolean;
}

export interface PlaylistUpdate {
  id: string;
  currentTime: number;
  isPaused: boolean;
  duration: number;
}

export interface PlaylistUpdatePayload extends PlaylistUpdate, VideoDetails {}

export interface PlaylistUpdateResponse {
  playerMeta: PlayerMeta;
  playlistQueue: VideoDetails[];
  lastPlayerUpdate: Date;
  playerSettings?: PlayerSettings;
}

export type PlayerSettingsPayload = Partial<PlayerSettings>;

// Mapping interfaces
export interface EventPayloads {
  [SocketEvent.MESSAGE]: MessagePayload;
  [SocketEvent.SEARCH]: SearchEventPayload;
  [SocketEvent.ADD_TO_PLAYLIST]: AddToPlaylistEventPayload;
  [SocketEvent.REMOVE_FROM_PLAYLIST]: RemoveFromPlaylistEventPayload;
  [SocketEvent.PLAYER_NEXT]: null | undefined;
  [SocketEvent.PLAYER_PAUSE]: null | undefined;
  [SocketEvent.PLAYER_PLAY]: null | undefined;
  [SocketEvent.PLAYLIST_PLAY]: number;
  [SocketEvent.PLAYLIST_UPDATE]: PlaylistUpdateResponse;
  [SocketEvent.UPDATE_PLAYER_SETTINGS]: PlayerSettingsPayload;
}

export interface EventResponses {
  [SocketEvent.MESSAGE]: MessagePayload; // No response expected for message event
  [SocketEvent.SEARCH]: SearchEventResponse;
  [SocketEvent.ADD_TO_PLAYLIST]: void;
  [SocketEvent.REMOVE_FROM_PLAYLIST]: void;
  [SocketEvent.PLAYLIST_UPDATE]: PlaylistUpdateResponse;
  [SocketEvent.CONNECT_ERROR]: string;
  [SocketEvent.ERROR]: string;
  [SocketEvent.PLAYER_NEXT]: void;
  [SocketEvent.PLAYER_PAUSE]: void;
  [SocketEvent.PLAYER_PLAY]: void;
  [SocketEvent.PLAYLIST_PLAY]: void;
  [SocketEvent.UPDATE_PLAYER_SETTINGS]: void;
  // ... other event responses
}
