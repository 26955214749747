import { SocketEvent } from "@common/SocketEvents";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import {
  AppBar,
  Avatar,
  Grid,
  IconButton,
  LinearProgress,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { forwardRef } from "react";
import { useAppContext } from "../contexts/App.context";
import { useSocket } from "../contexts/Socket.context";
interface BottomBarState {}
const BottomBar = forwardRef<HTMLElement, BottomBarState>((_props, ref) => {
  const { sendRequest } = useSocket();

  const { data } = useAppContext();
  const { administratorOverride } = data;
  const { playerMeta, playerSettings } = data.playlistData;

  const currentVideo = playerMeta.currentVideo;

  const musicTitle = currentVideo?.title;
  const musicArtist = currentVideo?.channelName;
  const musicThumbnail = currentVideo?.thumbnail;
  const isPlaying = playerMeta?.isPlaying;

  const handlePlayNext = () => {
    return sendRequest(SocketEvent.PLAYER_NEXT, null);
  };
  const handlePlayPause = () => {
    const playerEvent = playerMeta?.isPlaying
      ? SocketEvent.PLAYER_PAUSE
      : SocketEvent.PLAYER_PLAY;

    return sendRequest(playerEvent, null);
  };

  return (
    <Slide direction="up" in={!!currentVideo} mountOnEnter unmountOnExit>
      <AppBar
        position="fixed"
        sx={{
          zIndex: 100,
          top: "auto",
          bottom: 0,
          left: 0,
          width: "100%",
          paddingTop: (theme) => theme.spacing(0.5),
          paddingBottom: 0,
          background: "rgba(0,0,0,.85)",
        }}
        ref={ref}
        component={"footer"}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={2} wrap="nowrap">
            <Grid item>
              <Avatar
                variant="square"
                src={musicThumbnail}
                alt={musicTitle}
                sx={{
                  backgroundColor: "black",
                  width: (theme) => theme.spacing(6),
                  height: (theme) => theme.spacing(6),
                }}
              >
                {!musicThumbnail && "🎵"}
              </Avatar>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontWeight: 500,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {musicTitle}
              </Typography>
              <Typography
                variant="subtitle1"
                noWrap
                sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {musicArtist}
              </Typography>
            </Grid>
            {(!playerSettings?.musicPlayControlLocked ||
              administratorOverride) && (
              <Grid item>
                <IconButton
                  color="inherit"
                  onClick={handlePlayPause}
                  size="large"
                >
                  {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>
                <IconButton
                  color="inherit"
                  onClick={handlePlayNext}
                  size="large"
                >
                  <SkipNextIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Toolbar>
        <LinearProgress
          variant="determinate"
          value={
            (playerMeta.currentProgress / (currentVideo?.duration || 0)) * 100
          }
        />
      </AppBar>
    </Slide>
  );
});
export default BottomBar;
